<template>
  <div v-if="showOrderProduct" class="cmp-product-variant" :class="{ 'stock-counter': stockType === 1 }">
    <div class="variant-key">
      <span>{{ variantValue }}</span>
    </div>
    <div class="custom-select-box">
      <div class="current" @click="openDropdown()" v-outside-click="{ handler: 'closeDropdown', exclude: ['dropdown']}" ref="dropdown">
        <span class="variant-value">{{ product.properties[variantValue] }}</span>
        <utlz-product-stock v-if="showStock" :stock="product.stock"></utlz-product-stock>
        <utlz-product-price v-if="product.prices.length" :prices="product.prices[0]"></utlz-product-price>
        <i class="fa fa-angle-down"></i>
      </div>
      <ul v-if="dropdownOpen" class="options">
        <li
          v-for="(product, index) in computedProductVariants"
          :key="index" @click="updateSelected(index)"
          :class="{ selected: selectedIndex === index }"
          class="option"
          >
          <span class="grp-val">{{ product.properties[variantValue] }}</span>
          <utlz-product-stock v-if="showStock" :stock="product.stock"></utlz-product-stock>
          <utlz-product-price v-if="product.prices.length" :prices="product.prices[0]"></utlz-product-price>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Product } from 'src/models/product.model';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
export default {
  components: {
    'utlz-product-stock': ProductStock,
    'utlz-product-price': ProductPrice
  },
  props: {
    product: { type: Product, required: true },
    productVariants: { type: Array, required: true }
  },
  data () {
    return {
      variantValue: Object.keys(this.product.properties)[0],
      dropdownUsed: false,
      dropdownOpen: false,
      selectedIndex: 0
    }
  },
  computed: {
    ...mapGetters(['showStock', 'showOrderProduct', 'showPrices', 'stockType']),
    computedProductVariants () {
      return this.productVariants.length ? this.productVariants : [this.product];
    }
  },
  methods: {
    ...mapActions('elastic', ['getProductVariants']),
    openDropdown () {
      if (!this.dropdownUsed) {
        this.getProductVariants({ variantCode: this.product.id, productId: this.product.id });
        this.dropdownUsed = true;
      };
      this.dropdownOpen = !this.dropdownOpen;
    },
    updateSelected (index) {
      if (this.productVariants.length) {
        this.selectedIndex = index;
        this.$emit('selectedProduct', this.productVariants[index].id);
      }
    },
    closeDropdown () {
      this.dropdownOpen = false;
    }
  }
}
</script>

<style>

</style>
