const state = {};

const getters = {
  bespokeGlobalCms (state) {
    return state.bespokeGlobalCms;
  }
};

const mutations = {
  setGlobalCmsSettings (state, globalCmsObj) {
    Object.assign(state, globalCmsObj);
  }
};

const actions = {
  setGlobalCmsSettings ({ commit }, globalCmsObj) {
    commit('setGlobalCmsSettings', globalCmsObj);
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
