<template>
  <div class="utlz-order-grouped">
    <a class="group-option selected" @click="expandSelect">current</a>
    <ul v-if="selectExpanded" class="group-select custom-select">
      <li v-for="(product, index) in product.groupedProducts" :key="index" class="group-option">
        <div class="option-thumbnail">
          <div class="img-square">
            <img :src="product.imageUrl" class="img-fluid">
          </div>
        </div>
        <div class="text">
          <span class="font-xs">{{product.id}}</span>
          <h3 class="option-header">{{product.shortDescription.NL}}</h3>
        </div>
        <span>€ {{product.defaultPrice}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { Product } from 'src/models/product.model';
export default {
  props: {
    initialValue: { type: Number, required: false, default: 1 },
    product: { type: Product, required: true }
  },
  data () {
    return {
      selectExpanded: false,
      quantityValue: this.initialValue
    }
  },
  methods: {
    expandSelect () {
      this.selectExpanded = !this.selectExpanded;
    }
  }
}
</script>

<style>

</style>
