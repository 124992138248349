<template>
  <div class="utlz-shopping-cart-control">
    <ul>
      <li v-for="row in shoppingCart" v-bind:key="row.id">
        <h5>{{ row.id }}</h5>
      </li>
    </ul>
    <span>{{ totalPrice }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'shopping',
  computed: {
    ...mapGetters('shoppingCart', ['shoppingCart', 'totalPrice'])
  }
};
</script>

<style>
</style>
