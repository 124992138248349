export function formatPrice (price, decimals = 2) {
  return '€ ' + price.toFixed(decimals).replace('.', ',');
}

// Navigates to vb page, and gives the option to cache current store
export function navigateToHybridUrl (url, { cacheState, moduleKey, stateTree }) {
  if (cacheState) {
    window.sessionStorage[moduleKey] = JSON.stringify(stateTree);
  }
  window.location.href = url;
}

export function retrieveStore (moduleKey) {
  return JSON.parse(window.sessionStorage[moduleKey]);
}
