const state = {
};

const getters = {
  state (state) {
    return state;
  },
  language (state) {
    return state.language;
  },
  showPrices (state) {
    return state.showPrices;
  },
  showStock (state) {
    return state.showStock;
  },
  stockType (state) {
    return state.stockType;
  },
  stockLimit (state) {
    return state.stockLimit;
  },
  stockIndicators (state) {
    return state.stockIndicators;
  },
  showOrderProduct (state) {
    return state.showOrderProduct;
  },
  showFavorites (state) {
    return state.showFavorites;
  },
  groupProductsList (state) {
    return state.groupProductsList;
  },
  groupProducts (state) {
    return state.groupProducts;
  },
  filterSorting (state) {
    return state.filterSorting;
  },
  useOrderLineComments (state) {
    return state.useOrderLineComments;
  },
  productBlockSize (state) {
    return state.productBlockSize;
  },
  relatedProductBlockSize (state) {
    return state.relatedProductBlockSize;
  },
  minScore (state) {
    return state.minScore;
  },
  saleBannerColors (state) {
    return {
      color: state.saleBannerConfig.saleBannerColor,
      textColor: state.saleBannerConfig.saleBannerTextColor,
      text: state.saleBannerConfig.text
    }
  },
  bespokeGlobalWs (state) {
    return state.bespokeGlobalWs;
  }
};

const mutations = {
  setGlobalWsSettings (state, globalWsSettingsObj) {
    Object.assign(state, globalWsSettingsObj);
  }
};

const actions = {
  setGlobalWsSettings ({ commit }, globalWsSettings) {
    commit('setGlobalWsSettings', globalWsSettings);
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
