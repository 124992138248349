import store from 'src/store/store';

const state = {
  screenWidth: 0,
  screenHeight: 0,
  scrollPositionTop: 0
};

const getters = {
  screenWidth (state) {
    return state.screenWidth;
  },
  scrollPositionTop (state) {
    return state.scrollPositionTop
  }
};

const mutations = {
  resizeWindow (state, { width, height }) {
    state.screenWidth = width;
    state.screenHeight = height;
  },
  scroll (state, { pageYOffset }) {
    state.scrollPositionTop = pageYOffset;
  }
};

// Update state without mutations as these
// changes dont need to be tracked as mutations
const actions = {
  initBrowserDetection ({ state }) {
    state.screenWidth = window.innerWidth
    state.screenHeight = window.innerHeight;
  },
  resizeWindow ({ commit, state }, { width, height }) {
    state.screenWidth = width;
    state.screenHeight = height;
  },
  scroll ({ commit }) {
    commit('scroll', { pageYOffset: window.pageYOffset });
  }
};

window.addEventListener('resize', function (ev) {
  store.commit('resizeWindow', { width: window.innerWidth, height: window.innerHeight });
});

window.addEventListener('scroll', function (ev) {
  store.dispatch('scroll');
});

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
