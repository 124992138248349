<template>
  <div class="cmp-product-videos utlz-row">
    <div v-for="(video, index) in videos" :key="index" class="utlz-col-sm-6">
      <div class="embed-responsive embed-responsive-16by9">
        <iframe class="youtube-player embed-responsive-item" :src="video.url" frameBorder="0" type="text/html" allowfullscreen></iframe>
      </div>
      <h3 class="video-title">{{ video.description }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videos: { type: Array, required: true }
  }
}
</script>

<style>

</style>
