<template>
  <div class="cmp-order-saleunit">
    <span v-translation="{ type: 'label', code: 'label_sale_unit' } " class="sale-unit-label"></span>
    <span class="sale-unit-value">{{ saleUnit }}</span>
  </div>
</template>

<script>
export default {
  props: {
    saleUnit: { type: Number, required: true }
  }
};
</script>

<style>
</style>
