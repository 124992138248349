<template>
  <div class="cmp-pagination">
    <ul class="pagination-list flex-row-nowrap justify-content-center">

      <li class="previous-page" v-if="currentPage > 1">
        <a @click="updatePage(currentPage - 1)" class="button square fa fa-angle-left"></a>
      </li>

      <li v-for="(page, index) in pages" :key="index + 1">

        <template v-if="page.type === 'elipsis'">
          <a class="button square elipsis">{{ page.value }}</a>
        </template>

        <template v-if="page.type === 'page'">
          <a @click="updatePage(page.value)"
            class="button square-breakout"
            :class="{ active: page.value === currentPage, 'theme-primary': page.value === currentPage }"
          >{{ page.value }}</a>
        </template>

      </li>

      <li class="next-page" v-if="currentPage < totalPages">
        <a @click="updatePage(parseInt(currentPage + 1))" class="button square fa fa-angle-right"></a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    totalPages: { type: Number, required: true, default: 1 },
    currentPage: { type: Number, required: false, default: 1 },
    scrollToTop: { type: Boolean, required: false },
    scrollToElementId: { type: String, required: false }
  },
  computed: {
    pages () {
      const pages = [];

      let start = ((this.currentPage - 2 < 1) ? 1 : this.currentPage - 2);

      if (start > 1) {
        pages.push({ type: 'page', value: 1 });
      }

      if (start > 2) {
        pages.push({ type: 'elipsis', value: '...' })
      }

      for (var i = start; i <= this.currentPage; i++) {
        pages.push({ type: 'page', value: i });
      }

      if (this.currentPage + 1 < this.totalPages) {
        var newPageNr = this.currentPage + 1;
        pages.push({ type: 'page', value: newPageNr });

        newPageNr++;

        pages.push({ type: 'page', value: newPageNr });

        if (newPageNr < this.totalPages - 1) {
          pages.push({ type: 'elipsis', value: '...' });
        }
        if (newPageNr < this.totalPages) {
          pages.push({ type: 'page', value: this.totalPages });
        }
      } else if (this.currentPage !== this.totalPages) {
        pages.push({ type: 'page', value: this.totalPages });
      }
      return pages;
    }
  },
  methods: {
    updatePage (pageNumber) {
      this.$emit('changePage', pageNumber);
      if (this.scrollToTop) {
        window.scroll({ top: 0 });
      } else if (this.scrollToElementId) {
        document.getElementById(this.scrollToElementId).scrollIntoView();
      }
    }
  }
}
</script>

<style>

</style>
