let handleOutsideClick;

const OutsideClickDirective = {
  inserted (el, binding, vnode) {
    const { handler, exclude } = binding.value;

    handleOutsideClick = (ev) => {
      ev.stopPropagation();
      let clickedOnExcludedEl = false;
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl.contains(ev.target);
        }
        if (!el.contains(ev.target) && !clickedOnExcludedEl) {
          vnode.context[handler]();
        }
      })
    }
    document.addEventListener('click', handleOutsideClick);
  },
  unbind () {
    document.removeEventListener('click', handleOutsideClick);
  }
};

export default OutsideClickDirective;
