const TranslationDirective = {
  inserted (el, { value }) {
    let translation = window.vue.translations[value.type][value.code];
    el.innerHTML = translation;
  },
  componentUpdated (el, { value }) {
    let translation = window.vue.translations[value.type][value.code];
    if (value.interpolations !== undefined) {
      let newTranslation = translation;
      value.interpolations.forEach((interpolation, index) => {
        let ind = index + 1;
        newTranslation = newTranslation.replace('[' + ind + ']', interpolation);
      });
      translation = newTranslation;
    }
    el.innerHTML = translation;
  }
}

export default TranslationDirective;
