<template>
  <div class="cmp-order-volume-discount">
    <div class="custom-select-box">
      <div class="current" @click="selectExpanded = !selectExpanded" v-outside-click="{ handler: 'closeDropdown', exclude: ['dropdown']}" ref="dropdown">
        <span class="prefix">Staffel</span>
        <span class="volume-amount">{{ volumeDiscount[selectedIndex].quantity }}</span>
        <span class="multiply">x</span>
        <span class="volume-price">{{ volumeDiscount[selectedIndex].price }}</span>
        <i class="fa fa-angle-down"></i>
      </div>
      <ul v-if="selectExpanded" class="options">
        <li v-for="(volume, index) in volumeDiscount" :key="index" @click="updateSelected(index)" class="option" v-bind:class="{ selected: selectedIndex === index }">
          <span class="prefix">Staffel</span>
          <span class="volume-amount">{{ volume.quantity }}</span>
          <span class="multiply">x</span>
          <span class="volume-price">{{ volume.price }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialVolume: { type: Object, required: false },
    volumeDiscount: { type: Array, required: true }
  },
  data () {
    return {
      selectExpanded: false,
      selectedIndex: 0
    }
  },
  methods: {
    expandSelect () {
      this.selectExpanded = true;
    },
    updateSelected (index) {
      this.selectedIndex = index;
      this.$emit('volumeDiscountChanged', this.volumeDiscount[index].quantity);
    },
    closeDropdown () {
      this.selectExpanded = false;
    }
  }
}
</script>

<style>

</style>
