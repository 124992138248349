<template>
  <div class="cmp-gallery" :class="{ lightbox: lightboxActive, singular: product.images.length === 1 }">
    <i v-if="lightboxActive" @click="lightboxActive = false" class="fa fa-times-circle close-lightbox-btn"></i>
    <div class="gallery-wrapper">
      <div class="current-image">
        <div class="magnify-icon">
          <i class="fa fa-search-plus" aria-hidden="true"></i>
        </div>
        <div v-if="activeIndex !== 0" class="nav-control left" @click="navigate(0)">
          <i class="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        <div v-if="activeIndex !== product.images.length - 1" class="nav-control right" @click="navigate(1)">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        <transition name="fadein">
          <img v-if="product.images[activeIndex]" :src="product.images[activeIndex].replace('?r=pr', '?r=')" @click="lightboxActive = true" />
        </transition>
      </div>

      <div v-if="product.images.length > 1" class="thumb-list flex-row-nowrap">
        <div
          v-for="(image, index) of product.images"
          :class="{ 'active': index === activeIndex }"
          :key="index"
          @click="selectImage(index)"
          class="thumb-container">
          <div class="thumb-img">
            <img :src="image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Product } from 'src/models/product.model';
export default {
  props: {
    product: { type: Product, required: true }
  },
  data () {
    return {
      activeIndex: 0,
      lightboxActive: false
    };
  },
  methods: {
    selectImage (index) {
      this.activeIndex = index;
    },
    navigate (val) {
      if (val) {
        this.activeIndex++;
      } else {
        this.activeIndex--;
      }
    }
  }
}
</script>

<style>

</style>
