const state = {
};

const getters = {
  productStockEndpoint (state) {
    return state.productStockUrl;
  },
  orderProductEndpoint (state) {
    return state.orderProductUrl;
  },
  productPriceEndpoint (state) {
    return state.productPriceUrl;
  },
  getProductsInListsEndpoint (state) {
    return state.customerList + '/GetProductLists';
  },
  addProductToListEndpoint (state) {
    return state.customerList + '/AddProduct';
  },
  removeProductFromListEndpoint (state) {
    return state.customerList + '/RemoveProduct';
  },
  productsFromList (state) {
    return state.customerList + '/GetProductsFromList';
  },
  elasticProductsEndpoint (state) {
    return state.elasticProducts;
  },
  productInformationEndpoint (state) {
    return state.productInformation;
  },
  seoInformationEndpoint (state) {
    return state.seoInformation;
  },
  productVariantsEndpoint (state) {
    return state.elasticProducts + '/GetByProductVariantKey'
  },
  customerListEndpoint (state) {
    return state.customerList;
  },
  productVariantDescriptionEndpoint (state) {
    return state.productVariantDescription;
  },
  relatedProductsEndpoint (state) {
    return state.relatedProductsUrl;
  },
  productVideosEndpoint (state) {
    return state.productVideosUrl;
  },
  productDocumentsEndpoint (state) {
    return state.productDocumentsUrl;
  },
  productImageBannersUrl (state) {
    return state.productImageBannersUrl;
  },
  bespokeProductFieldsEndpoint (state) {
    return state.bespokeFieldsUrl
  },
  bespokeEndpoints (state) {
    return state.bespokeEndpoints;
  }
};

const mutations = {
  setGlobalEndpoints (state, globalEndpointsObj) {
    Object.assign(state, globalEndpointsObj);
  }
};

const actions = {
  setGlobalEndpoints ({ commit }, globalEndpointsObj) {
    commit('setGlobalEndpoints', globalEndpointsObj);
  }
};

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};
